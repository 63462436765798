<template>
  <div class="box">
    <h1>{{ detail.title }}</h1>
    <div class="content" v-html="detail.tourism"></div>
  </div>
</template>

<script lang="js">
import {  defineComponent,onMounted,ref } from "vue";
import { useRouter } from 'vue-router'

// import { ElSlider } from "element-plus";

export default defineComponent({
  name: "event",
  components:{  },
  setup() {
    //  const style={ }
    const router = useRouter();
    const detail=ref({});
    // const displayData=reactive({});
    // const keyToName={address:'标题',message:'事件内容',realname:'事件处理人员',state:{name:'事件处理状态',enum:['未处理','处理中','已完成','未完成']},result:'事件处理结果'};

    onMounted(() => {
      detail.value=JSON.parse(decodeURIComponent(router.currentRoute.value.query.one))
      // eslint-disable-next-line no-useless-escape
      detail.value.tourism=detail.value.tourism.replace(/\<img/g,'<img style="width:30%;height:auto;border-radius: 5%;margin: 0 auto;display: block;"');
    })
      return { detail };
    },
});
</script>

<style scope>
.box {
  /* overflow: hidden; */
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  /* background-color: blue; */
}
.box h1 {
  text-align: center;
  font-size: 50px;
}
.box .content {
  font-size: 20px;
}
.box p {
  text-indent: 2em;
}
</style>
